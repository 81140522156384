@import "../../styles/variaveis";

.bannerPages {
    background-size: cover;
    width: 100%;
    height: 33vh;

    display: flex;
    align-items: center;

    > h1 {
        font-size: 40px;
        color: white;
        text-transform: uppercase;
        padding-left: 10%;
        letter-spacing: 1px;

        text-shadow: 1px 1px 1px black;
    }
}