@import '../../styles/variaveis';

.menu {
    /* background-color: white; */
    display: flex; 
    justify-content: space-evenly;
    width: 600px;
    min-width: 560px;

    a {
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 300;
        position: relative;
        padding: 0 10px 0 10px;
    }

    .link-menu:after {
        content: "";
        position: absolute;
        background-color: $hoverMenu;
        height: 1px;
        width: 0;
        left: 7px;
        bottom: -1px;
        transition: 0.2s;
    
        border-radius: 1px;
    }

    .link-menu:hover::after {
        width: 90%;
    }

    .link-active:after {
        color: red;
        width: 90%;
    }

    .home {
        font-weight: 200;
        color: rgb(255, 255, 255);
    }
}

@media only screen and (max-width: 1400px) {
    
    .menu {
        /* background-color: white; */
        display: flex; 
        justify-content: space-evenly;
        width: 500px;
        min-width: 540px;
    
        a {
            font-size: 15px;
            letter-spacing: 1px;
            font-weight: 300;
            position: relative;
            padding: 0 7px;
        }
    }
}

@media only screen and (max-width: 1300px) {
    
    .menu {
        width: 400px;
        min-width: 480px;
    
        a {
            font-size: 14px;
            padding: 0 5px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    
    .menu {
        width: 400px;
        min-width: 480px;
    
        a {
            font-size: 12px;
            padding: 0 5px;
        }
    }
}

@media only screen and (max-width: 1100px) {
    
    .menu {    
        min-width: 400px;
        a {
            font-size: 11px;
            padding: 0 3px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .menu {
        display: none;
    }
}