@import "../../styles/variaveis";

.menuHamburguer {
    top: 0;
    right: 0;
    padding-right: 10px;

    display: none;

    > img {
        width: 35px;
    }

    .menuLista {
        width: 100vw;
        background-color: rgb(19, 19, 19);
        position: absolute;
        top: 9vh;
        right: 0;
        transition: top 0.3s ease;

        > hr {
            background: $gradientDourado;
            width: 100%;
            height: 1px;

            margin: 0;
            padding: 0;
        }

        > li {
            width: 100%;

            > a {
                width: 100%;
                color: rgb(230, 230, 230);

                padding: 0.5rem 1rem;

                display: flex;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .menuHamburguer {
        display: flex;
    }
}
