@import "../../styles/variaveis";

.ContatoSection {
    background-image: url('../../assets/backgrounds/cimentoCinza.jpg');
    background-size: cover;
    width: 100%;
    height: 80vh;

    color: white;

    padding: 4vh 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    &_titulo {
        color: rgb(211, 211, 211);
        font-size: 32px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &_subTitulo {
        font-size: 16px;
        font-weight: 200;
        padding: .2rem 0;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__container {
        width: 100%;
        padding: 0 10vw;

        margin: 5vh 0;

        display: flex;
        justify-content: space-evenly;

        &_left {
            width: 36%;
            display: flex;
            flex-direction: column;
            align-items: center;

            > h3 {
                font-weight: 300;
                margin-bottom: 1vh;
            }
        }

        &_right {
            width: 35%;
            max-width: 40%;

            display: flex;
            flex-direction: column;

            > hr {
                width: 70%;
                background-color: rgb(255, 255, 255);
                height: 1px;
                border: none;
            }

            > h3 {
                margin: 1rem 10px;
                font-weight: 400;
            }
        }
    }
}
