@import '../../styles/variaveis';


.copy {
    background-color: black;
    font-size: 14px;
    color: white;
    padding: .8rem 0;

    display: grid;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    grid-template-columns: repeat(3, 1fr); /* Três colunas iguais */
    text-align: center;

    &_gabbsDev {
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            cursor: pointer;
            margin-left: .6rem;
            width: 100px;
        }
    }
}

@media screen and (max-width: 768px) {
    .copy {
        grid-template-columns: repeat(2, 1fr); /* Duas colunas iguais */
        grid-template-rows: auto auto; /* Duas linhas automáticas */
        grid-template-areas:
            "gabbsDev privacy"
            "gabbsDev copyright";

        > a {
            grid-area: privacy;
        }

        > p {
            grid-area: copyright;
        }

        .copy_gabbsDev {
            grid-area: gabbsDev;
        }
    }
}

@media screen and (max-width: 450px) {
    .copy {
        grid-template-columns: 1fr; /* Uma coluna */
        grid-template-rows: auto auto auto; /* Três linhas automáticas */
        grid-template-areas:
        "gabbsDev"
        "privacy"
        "copyright"
    }
}