@import "../../styles/variaveis";

.swiperClinicaHome {
    width: 930px;


    &_slide {
        > img {
            width: 300px;
            height: 500px;
        }
    }
}

@media only screen and (max-width: 1500px) {
    .swiperClinicaHome {
        width: 800px;

        &_slide {
            > img {
                width: 250px;
                height: 450px;
            }
        }
    }
}

@media only screen and (max-width: 1380px) {
    .swiperClinicaHome {
        width: 700px;

        &_slide {
            > img {
                width: 220px;
                height: 400px;
            }
        }
    }
}