@import "../../../styles/variaveis";

.mainArea {
    margin-top: 8vh;
}
.area-sobre {
    background: linear-gradient(to left, #e2cfbb65, #e2cfbb7a);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 100px;

    padding: 5vh 0 6vh 0;

    &__left {
        width: 35%;
        display: flex;
        flex-direction: column;

        > h1 {
            font-size: 32px;
            font-weight: 500;
            margin-top: 1rem;
        }

        > hr {
            background: black;
            width: 100px;
            height: 3px;
            border: none;

            margin-top: 0.3rem;
            margin-bottom: 1.5rem;
        }

        > p {
            width: 80%;
            font-size: 18px;
            font-weight: 300;

            margin: 0.5rem 0;

            > b {
                font-weight: 700;
            }
        }
    }

    &__right {
        position: relative;

        > img {
            width: 350px;
            height: 525px;
            box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.418);
            border-radius: 2px;
        }

        &-detalheImg {
            width: 350px;
            height: 525px;
            border: 10px solid $douradoEscuro;

            border-image: $gradientDouradoOpacity;
            border-radius: 2px;

            position: absolute;
            top: 30px;
            left: 30px;

            z-index: -1;
        }

        > .img-secundaria {
            display: none;
        }
    }
}

.area-mais {
    padding: 7vh 0;

    display: flex;
    justify-content: center;
    gap: 50px;

    > .video > div > iframe {
        width: 600px;
        height: 320px;
    }

    > .comoEhFeito {
        width: 35%;

        > h1 {
            margin-top: 2rem;
            font-size: 32px;
            font-weight: 500;
        }

        > p {
            width: 85%;
            font-size: 18px;
            font-weight: 300;

            margin: 0.5rem 0;
        }
    }
}

hr {
    background: black;
    width: 100px;
    height: 3px;
    border: none;

    margin-top: 0.3rem;
    margin-bottom: 1.5rem;
}

@media (max-width: 1450px) {
    .area-sobre {
        gap: 50px;

        &__left {
            width: 45%;
        }
    }

    .area-mais {
        padding: 5vh 0;
        gap: 30px;

        > .comoEhFeito {
            width: 40%;
            > p {
                width: 90%;
            }
        }
    }
}

/* Media Queries */
@media (max-width: 1200px) {
    .area-sobre {
        &__left {
            width: 50%;
        }
    }

    .area-mais {
        padding: 5vh 20px;
        gap: 30px;

        > .comoEhFeito {
            width: 40%;

            > h1 {
                font-size: 28px;
            }

            > p {
                width: 95%;
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 1050px) {
    .area-sobre {
        gap: 20px;

        &__left {
            > h1 {
                font-size: 28px;
            }

            > p {
                width: 85%;
                font-size: 16px;
            }
        }

        &__right {
            position: relative;

            > img {
                width: 320px;
                height: 480px;
            }

            &-detalheImg {
                width: 320px;
                height: 480px;
            }
        }
    }

    .area-mais {
        padding: 5vh 20px;
        gap: 30px;

        > .video > div > iframe {
            width: 500px;
            height: 280px;
        }

        > .comoEhFeito {
            width: 40%;

            > h1 {
                margin-top: 0em;
                font-size: 28px;
            }

            > p {
                width: 95%;
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 890px) {
    .area-sobre {
        gap: 20px;

        &__left {
            > h1 {
                font-size: 26px;
            }

            > p {
                width: 90%;
                font-size: 15px;
            }

            > hr {
                width: 70px;
                height: 2px;
            }
        }

        &__right {
            position: relative;

            > img {
                width: 300px;
                height: 450px;
            }

            &-detalheImg {
                width: 300px;
                height: 450px;
            }
        }
    }

    .area-mais {
        padding: 5vh 20px;
        gap: 30px;

        > .video > div > iframe {
            width: 420px;
            height: 235px;
        }

        > .comoEhFeito {
            width: 40%;

            > h1 {
                margin-top: 0em;
                font-size: 26px;
            }
            > hr {
                width: 70px;
                height: 2px;
                margin-bottom: 0.8em;
            }

            > p {
                width: 95%;
                font-size: 14px;
                margin: 0.3rem 0;
            }
        }
    }
}

@media (max-width: 768px) {
    .area-sobre {
        flex-direction: column-reverse;
        padding: 4vh 30px;

        &__left {
            width: 100%;
        }

        &__right {
            width: 100%;
            display: flex;
            justify-content: center;

            > img {
                width: 100%;
                height: auto;
            }

            &-detalheImg {
                display: none;
            }

            > .img-secundaria {
                display: inline-block;
            }

            > .img-principal {
                width: 100%;
                height: auto;
                display: none;
            }
        }
    }

    .area-mais {
        width: 100%;
        flex-direction: column-reverse;

        padding: 4vh 30px;

        > .video > div {
            width: 100%;
            display: flex;
            position: relative;
            width: 100%;
            padding-top: 56.2%; /* 2/3 da largura */
            overflow: hidden;
            > iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        > .comoEhFeito {
            width: 100%;

            > h1 {
                font-size: 26px;
                font-weight: 500;
            }

            > p {
                width: 90%;
                font-size: 15px;
                font-weight: 300;

                margin: 0.5rem 0;
            }
        }
    }
}

@media (max-width: 576px) {

    .area-sobre {
        padding: 4vh 20px;
    }

    .area-mais {
        padding: 4vh 20px;
    }
}
