@import './variaveis';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Faz com que o padding e border sejam considerados no cálculo do tamanho do elemento */
}

/* Tipografia */
html {
  font-family: Arial, sans-serif;
}

/* Listas */
ul, ol {
  list-style: none; /* Remove os marcadores de lista padrão */
}

/* Links */
a {
  text-decoration: none; /* Remove sublinhado padrão */
  color: inherit; /* Herda a cor do texto do elemento pai */
}

/* Elementos de formulário */
button, input, select, textarea {
  font-family: inherit; /* Herda a fonte do elemento pai */
}

button {
  cursor: pointer; /* Mostra o cursor como ponteiro ao passar por cima de botões */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  display: flex;
  flex-direction: column;
}

body {
  background: $branco;
  font-family: $fontePrincipal;
}

::selection {
  background-color: rgba(255, 255, 255, 0.301);
}