@import "../../styles/variaveis";

.clinica {
    height: 33vh;
    background-image: url("../../assets/imgPages/clinica.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;

    position: relative;

    margin-top: 8vh;

    &__titulo {
        width: 100%;
        font-size: 40px;
        color: white;
        text-transform: uppercase;
        padding: 0 10vw;
        letter-spacing: 1px;

        text-shadow: 1px 1px 1px black;
    }

    &__front {
        background: linear-gradient(to top, $branco, rgba(255, 255, 255, 0));
        width: 100%;
        height: 7vh;

        position: absolute;
        bottom: 0;
    }
}

.sobre {
    background-color: $branco;
    width: 100%;
    padding: 10vh 15vw;

    display: flex;
    justify-content: center;

    &__imagens {
        width: 51%;
        display: flex;
        gap: 20px;

        img {
            width: 16vw;
            height: 60vh;

            /* border: 1px solid black; */
        }

        &_two {
            margin-top: 50px;
        }
    }

    &__texto {
        padding-top: 1vh;
        width: 38%;

        > h1 {
            font-weight: 500;
            font-size: 36px;
        }

        > hr {
            height: 3px;
            width: 70px;
        }

        &_txt {
            margin-top: 2vh;
            
            p {
                text-align: justify;
                padding: 0.6em 0;
            }
        }
    }
}

.empresa {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 5vh 0;

    &__container {
        display: flex;
        position: relative; /* Adicionando posição relativa ao container */

        .card {
            background-color: white;
            width: 300px;
            height: 240px;
            margin: 2px;

            box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.315);
            padding: 1rem 2rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            align-self: center;
            z-index: 1;
            transition: 0.4s all;

            cursor: default;

            img {
                width: 35%;
            }

            h2 {
                font-size: 20px;
                font-weight: 400;
                padding: 0.2rem 0;
            }

            p {
                width: 250px;
                font-size: 14px;
                text-align: center;
            }
        }

        .card:hover {
            transform: scale(1.1);
            z-index: 2;
            transition: 0.4s all;
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.685);
        }
    }
}

.corpoClinico {
    /* background-color: rgb(114, 114, 114); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 10vh 0;
    padding: 5vh 0;

    &_titulo {
        font-size: 40px;
    }

    > span {

        margin-bottom: 7vh;
    }

    &_Div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 100px;

        &__txt {
            width: 25%;
            display: flex;
            flex-direction: column;
            align-items: center;

            & > h1 {
                font-family: sans-serif;
                font-weight: 400;

                margin: 0 0 1.2rem 0;
            }

            p {
                text-align: justify;
            }
        }

        &__img {
            width: 20%;

            .imgDoutor {
                width: 420px;
                border-radius: 2px;
            }
        }
    }
}


@media only screen and (max-width: 768px) {



    .corpoClinico, .sobre, .empresa, .clinica {
        display: none;
    }
}