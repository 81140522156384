@import '../../styles/variaveis';

.mainBlog {
    background-color: rgb(211, 211, 211);
    margin-top: 9vh;
}

.cards {
    width: 70%;
    max-width: 80%;
    margin: 0vh auto;

    padding: 5vh 0;

    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 1750px) {
    .cards {
        width: 75%;
    }
}

@media screen and (max-width: 1600px) {
    .cards {
        width: 75%;
    }
}

@media screen and (max-width: 1400px) {
    .cards {
        width: 78%;
    }
}

@media screen and (max-width: 1300px) {
    .cards {
        width: 80%;
    }
}