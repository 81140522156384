@import '../../styles/variaveis';

.NavBar {
    background: rgb(17, 17, 17);
    width: 100%;
    height: 9vh;
    color: white;

    min-height: 50px;

    padding: 0 12vw;

    position: fixed;
    top: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: space-between;

    align-self: center;

    
    &_youtube {
        > h4 {
            font-weight: 300;
            font-size: 17px;
    
            border-radius: 40px;
        }

        display: flex;
        align-items: center;

        > img {
            width: 40px;
        }
    }
}

@media only screen and (max-width: 1650px) {
    
    .NavBar {
        width: 100%;
    
        padding: 0 10vw;

    }
}

@media only screen and (max-width: 1500px) {
    
    .NavBar {
        width: 100%;
    
        padding: 0 5vw;

    }
}

@media only screen and (max-width: 1300px) {
    
    .NavBar {
        &_youtube {
            > h4 {
                font-weight: 300;
                font-size: 14px;
        
                border-radius: 40px;
            }
    
            display: flex;
            align-items: center;
    
            > img {
                width: 35px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    
    .NavBar {
        h4 {
            display: none;
        }
    }
}

@media only screen and (max-width: 850px) {
    
    .NavBar {
        padding: 0 2%;
    }
}

@media only screen and (max-width: 768px) {
    
    .NavBar {
        background: rgb(26, 26, 26);
        &_youtube {
            display: none;
        }
    }
}

@media only screen and (max-height: 650px) {
    
    .NavBar {
        &_youtube {
            > h4 {
                font-weight: 400;
                font-size: 15px;
            }
    
            > img {
                width: 35px;
            }
        }
    }
}