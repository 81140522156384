@import '../../styles/variaveis';

.mainAreas {
    margin-top: 8vh;
}

.AreasDeAtuacao {    
    width: 100%;

    > button {
        color: black;
    }
}

@media only screen and (max-width: 768px) {

}