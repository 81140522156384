@import "../../styles/variaveis";
@import "../../styles/globalStyles.scss";

.footer {
    /* background-image: url('../../assets/imgPages/footer.jpg');
    background-size: cover; */
    background-color: $footerBlack;
    color: rgb(235, 235, 235);
    width: 100%;
    display: flex;
    justify-content: space-between;

    padding: 5vh 10%;

    &_marca {
        min-width: 290px;
    }

    &_marca__p {
        padding-top: 4rem;
    }

    &_itens {
        width: calc(3 * 350px);
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &_div {
        width: 280px;
        min-width: 280px;
        margin: 0 20px 20px 20px;

        &Conteudo {
            font-size: 16px;
            padding: 1rem 0;
            display: flex;
            flex-direction: column;

            &__item {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding-bottom: 0.8rem;
            }

            &_img {
                width: 22px;
                margin-right: 5px;
                align-self: flex-start;
            }

            > p {
                font-size: 16px;
                font-weight: 200;
            }
        }
    }

    &_subTitulo {
        display: flex;
        flex-direction: column;
        /* align-items: center; */

        hr {
            width: 50px;
            height: 2px;
            background: $dourado2;

            border: none;

            margin-top: 0.3rem;
            margin-bottom: .7rem;
        }

        h3 {
            font-size: 18px;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }

    &_footer {
        display: none;
    }
}

.display_flexCenter {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1700px) {
    .footer {
        padding: 5vh 5%;
    }
}

@media screen and (max-width: 1350px) {
    .footer {
        padding: 5vh 5vw;
    }
}

@media screen and (max-width: 1400px) {
    .footer {
        padding: 5vh 10% 0 10%;

        &_itens {
            width: calc(2 * 350px);
        }

        &_div {
            min-width: 250px;
        }
    }
}

@media screen and (max-width: 1150px) {
    .footer {
        &_itens {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;

        &_marca {
            display: none;
        }

        &_itens {
            width: calc(1 * 350px);
            flex-direction: column;
        }

        &_footer {
            display: block;
            margin: .5rem 1rem;
        }

        &_div {
            margin: 0 20px 00px 20px;
        }
    }
}

@media screen and (max-width: 480px) {
    .footer {
        padding: 5vh 5% 0 5%;

        &_marca {
            display: none;
        }

        &_itens {
            width: 100%;
            flex-direction: column;
        }
    }
}