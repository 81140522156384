@import "../../styles/variaveis";

.form {
    width: 100%;
    /* background-color: antiquewhite; */
    /* padding-right: 2em; */

    label {
        font-size: 14px;
        font-weight: 500;
        display: block;
    }

    .labelMaisFina {
        font-weight: 300;
    }

    #tel,
    #email,
    #nome,
    #message {
        background-color: rgba(128, 128, 128, 0.315);
        /* border-radius: 5px; */
        border: none;
        border-bottom: 2px solid black;

        padding: 0.4rem 1em;
        font-size: 16px;
    }

    #tel:focus,
    #email:focus,
    #nome:focus {
        outline: 1px solid black;
        background-color: rgba(0, 0, 0, 0.37);
        /* background: none; */ //aqui tem opção para o cliente
    }

    #message:focus {
        outline: 1px solid black;
        background-color: rgba(0, 0, 0, 0.37);
    }

    .white {
        color: white;
        font-weight: 300;
    }

    &__two {
        width: 100%;
        max-width: 100%;
        justify-content: space-between;
        gap: 10px;

        margin: 1em 0;

        &-email {
            width: 60%;
        }

        &-tel {
            width: 40%;
        }

        /* #email {
            width: 60%;
        } */
    }

    .textArea {
        background-color: rgba(128, 128, 128, 0.315);
    }

    &__btn {
        background: none;
        color: black;
        font-family: $fontePrincipal;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.2px;

        padding: 0.4rem 2rem;
        margin-top: 1rem;
        text-align: end;

        /* Defina o gradiente linear como a fonte da borda */
        border-image: linear-gradient(to right, #fff27f 20%, $dourado2, $dourado3) 1;

        /* Restante das propriedades de borda */
        /* border: 2px solid $preto; */
        /* border-radius: 20px; */
    }

    .btnWhite {
        color: white;
        font-weight: 400;
        border-image: linear-gradient(to right, #faf3b9bb 20%, $dourado2, $dourado3) 1;
    }
}

.Collum {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}
