@import '../../../styles/variaveis';

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.blog-card {
  border: 1px solid #ccc;
  padding: 20px;
}

.pagination {
  margin-top: 20px;
}

.pagination button {
  background: rgb(212, 212, 212);
  width: 42px;
  height: 42px;
  font-size: 16px;
  
  cursor: pointer;
  padding: 0.5rem;
  margin-right: 7px;
  border: 1px solid rgb(49, 49, 49);
  
  /* border-image: linear-gradient(to right, $dourado1 10%, $dourado3) 1; */  
}

.current-page {
  color: red;
}

@media screen and (max-width: 1000px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 810px) {
  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
