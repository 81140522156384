@import "../../styles/variaveis";

.cardAreas {
    background-color: rgb(224, 224, 224);
    width: 100%;
    height: 500px;

    display: flex;
    justify-content: center;

    padding: 5.2vh;

    > .imagem {
        position: relative;

        margin-right: 70px;

        > img {
            height: 100%;

            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.507);
        }

        /* > .detalheImagem {
            height: 100%;
            width: 438px;

             border: 10px solid rgba(0, 0, 0, 0.37);

            position: absolute;
            top: 30px;
            left: 30px;
        } */
    }

    > .info {
        width: 30%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        > h2 {
            text-transform: uppercase;
            letter-spacing: 1px;
        }

        > p {
            width: 100%;

            margin: 1rem 0 3rem 0;
        }

        > button {
            background: none;
            font-size: 18px;
            padding: 0.2rem 1rem;
            border: 2px solid black;
            transition: 0.3s all;

            &:hover {
                background-color: rgb(34, 34, 34);
                color: white;
                font-size: 18px;
                font-weight: 300;

                transition: 0.3s all;
            }
        }
    }
}

.dark {
    background-image: url("../../assets/backgrounds/gessoEscuro.jpg");
    color: white;

    > .imagem {
        /* > img {
            box-shadow: 4px 4px 10px rgba(255, 255, 255, 0.089);
        } */

        > .detalheImagem {
            height: 100%;
        }
    }

    > .info {
        > h2 {
            font-weight: 600;
        }

        > button {
            font-weight: 400;
            color: white;
            border: 2px solid white;
            transition: 0.3s all;

            &:hover {
                background-color: rgb(245, 245, 245);
                color: black;
                font-size: 18px;
                font-weight: 500;

                transition: 0.3s all;
            }
        }
    }
}

@media only screen and (max-width: 1680px) {
    .cardAreas {
        height: 450px;
    }
}

@media only screen and (max-width: 1300px) {
    .cardAreas {
        background-color: rgb(224, 224, 224);
        width: 100%;
        height: 400px;

        display: flex;
        justify-content: center;

        padding: 5.2vh;

        > .imagem {
            position: relative;

            margin-right: 70px;

            > img {
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.507);
            }
        }

        > .info {
            width: 40%;
        }
    }
}

@media only screen and (max-width: 980px) {
    .cardAreas {
        background-color: rgb(224, 224, 224);
        width: 100%;
        height: 350px;

        display: flex;
        justify-content: center;

        padding: 4vh;

        > .info {
            width: 50%;

            > h2 {
                font-size: 20px;
            }

            > p {
                font-size: 14px;

                margin: 1rem 0 2rem 0;
            }

            > button {
                font-size: 16px;
                padding: 0.2rem 1rem;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .cardAreas {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: 5.2vh;

        > .imagem {
            margin: 0;
            > img {
                width: 100%;

                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.507);
            }
        }

        > .info {
            width: 100%;

            align-items: flex-start;
        }
    }
}
