//imports
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');

//fonts
$fontePrincipal: "Poppins", sans-serif;
$fonteWhatsApp: "Inter", sans-serif;
$fonteHandWhite: "Caveat", cursive;

//cores
$branco: #f5f5f5;
$preto: #000;
$hoverMenu: rgb(223, 223, 223);
$footerBlack: rgb(22, 22, 22);
$verdeWhatsApp: #64B161;

$dourado: #FFD700;
$douradoEscuro: #998200;

//dourado
$dourado1: #faf5b9;
$dourado2: #f0c672;
$dourado3: #f1b858;
$douradoPescoco: #da8733;

//gradient
$gradientDourado: linear-gradient(to right, $dourado1 20%/* , $dourado2 */, $dourado3,);
$gradientToTop: linear-gradient(to bottom, $dourado1 20%, $dourado2, $dourado3,);
$gradientDouradoOpacity: linear-gradient(to right, #faf3b9dc 20%, #f1b958d8) 1;