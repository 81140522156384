@import "../../styles/variaveis";

.cardArtigo {
    background-color: rgb(255, 255, 255);
    width: 100%;
    min-width: 390px;
    max-width: 420px;
    height: 480px;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.473);

    border-radius: 3px;
    overflow: hidden;

    transition: all 0.3s ease;
    cursor: pointer;

    &__img {
        position: relative;

        > img {
            width: 100%;
            height: 280px;
            padding: 10px 10px 0 10px;

            /*  border-radius: 13px; */
        }

        > span {
            color: rgb(29, 29, 29);
            background-color: rgba(255, 255, 255, 0.616);

            padding: 0.1em 0.8em;
            font-size: 14px;
            font-weight: 500;

            border-radius: 15px;

            position: absolute;
            right: 20px;
            bottom: 15px;
            z-index: 3;

        }
    }

    &__info {
        padding: 0 15px;

        > h2 {
            font-size: 22px;
            padding-top: 0;
        }

        > p {
            font-size: 16px;
            padding: 0.3rem 0 0.7rem 0;
        }

        > span {
            margin-top: 1vh;
        }
    }

    &:hover {
        transform: scale(1.1);
    }
}

@media screen and (max-width: 1700px) {
    .cardArtigo {
        min-width: 390px;
        height: 480px;

        &__img {
    
            > img {
                height: 260px;
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .cardArtigo {
        min-width: 390px;
        height: 440px;
        
        &__img {
    
            > img {
                height: 255px;
            }
        }

        &__info {
            padding: 0 15px;
    
            > h2 {
                font-size: 20px;
                padding-top: 0;
            }
    
            > p {
                font-size: 15px;
                padding: 0.3rem 0 0.7rem 0;
            }
    
            > span {
                margin-top: 1vh;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .cardArtigo {
        width: 200px;
        height: 380px;

        &__img {
    
            > img {
                height: 225px;
            }
        }

        &__info {
            padding: 0 15px;
    
            > h2 {
                font-size: 17px;
                padding-top: 0;
            }
    
            > p {
                font-size: 13px;
                padding: 0.3rem 0 0.7rem 0;
            }
    
            > span {
                margin-top: 1vh;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .cardArtigo {
        height: 450px;
        &:hover {
            transform: scale(1);
        }
    }
}

@media screen and (max-width: 1260px) {

}

@media screen and (max-width: 810px) {
    .cardArtigo {
        min-width: 470px;
    }
}

@media screen and (max-width: 550px) {
    .cardArtigo {
        min-width: 200px;
    }
}

@media screen and (max-width: 400px) {
    .cardArtigo {    
        max-height: 440px;
        &__img {    
            > img {
                width: 100%;
                height: 250px;
            }
        }

        &__info {    
            > h2 {
                font-size: 20px;
            }
    
            > p {
                font-size: 15px;
                padding: 0.5rem 0 0.7rem 0;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .cardArtigo {
        max-width: 420px;
        height: 480px;
    }
}
