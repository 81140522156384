@import '../../styles/variaveis';

.btnContato {
    background: none;
    font-size: 17px;
    font-weight: 500;
    padding: .5rem 3rem;
    margin: 1rem 0;
    border: 2px solid black;

    transition: .3s all;
}

.btnContato:hover {
    background-color: rgb(34, 34, 34);
    color: white;
    font-weight: 400;

    transition: .3s all;
}

@media (max-width: 1050px) {
    .btnContato {
        font-size: 15px;
    }
}