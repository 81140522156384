@import "../../styles/variaveis";

.main-contato {
    background: white;
}

.contato {
    background-image: url("https://joanaozi.com.br/wp-content/uploads/2022/06/slide-contato.jpg");
    background-size: cover;
    width: 100%;
    height: 33vh;

    display: flex;
    align-items: center;

    margin-top: 8vh;

    &__titulo {
        font-size: 40px;
        color: white;
        text-transform: uppercase;
        padding: 0 18vw;
        letter-spacing: 1px;

        text-shadow: 1px 1px 1px black;
    }

    &-mapa {
        background-color: white;
        width: 100%;
        height: 350px;
        margin-bottom: 5vh;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.123);

        > h3 {
            color: red;
            margin: 0 0 1em 1em;
        }

        > iframe {
            width: 600px;
        }
        &:focus-within {
            outline: none;
        }
    }
}

.info {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 5vh 0;

    &_h1 {
        font-size: 2.4rem;
    }

    &_texto {
        width: 50%;
        text-align: center;
        margin: .5em 0 0em 0;
    }

    .linha {
        background: linear-gradient(to right, #fff27f 20%, #f1b958) 0;
        width: 120px;
        height: 4px;
        border: none;
        /* border-top: 2px solid black; */
        /* border-radius: 20px; */

        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.226);
    }
}

.contato-container {
    padding: 0 18%;
    display: flex;
    gap: 50px;

    padding-bottom: 15vh;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;

        &__meiosDeContato {
            margin-bottom: 3vh;

            > h3 {
                font-weight: 600;

                margin-top: .9em;
            }
        }
    }
}


@media only screen and (max-width: 1600px) {
    .contato-container {
        padding: 0 15%;
        padding-bottom: 15vh;
    }
}

@media only screen and (max-width: 1400px) {
    .contato-container {
        padding: 0 10%;
        gap: 50px;
        padding-bottom: 10vh;
    }

    .info {    
        &_texto {
            width: 70%;
        }
    }
}

@media only screen and (max-width: 1150px) {
    .contato-container {
        padding: 0 5%;
        gap: 50px;
        padding-bottom: 10vh;
    }
}

@media only screen and (max-width: 1020px) {
    .contato-container {
        padding: 0 18%;
        display: flex;
        gap: 50px;
        flex-direction: column;
    
        padding-bottom: 15vh;
    
        .left {
            width: 100%;
        }
    
        .right {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {

    .contato {
        background-size: cover;
        height: 15vh;

        &__titulo {
            font-size: 32px;
            padding: 0 0 0 10%;
        }
    }

    .info {    
        margin: 2vh 0;
    
        &_h1 {
            font-size: 1.8rem;
        }
    
        &_texto {
            width: 80%;
            font-size: 12px;
            text-align: center;
            margin: .5em 0 .5em 0;
        }
    
        .linha {
            background: linear-gradient(to right, #fff27f 20%, #f1b958) 0;
            width: 80px;
            height: 3px;
            border: none;

            margin: 0 0 2vh 0;
        }
    }

    .contato-container {
        padding: 0;
        gap: 50px;
        padding-bottom: 10vh;

        .left {
            padding: 0 5%;
        }
    
        .right {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__meiosDeContato {
                width: 90%;

                > h3 {
                    align-self: center;
                }
            }
        }
    }

    iframe {
        width: 100%;
        height: 30vh;
    }
}