@import '../../styles/variaveis';

.cardPrev {
    
    &__item {
        background-color: rgb(248, 248, 248);
        width: 100%;
        height: 85px;

        display: flex;
        align-items: center;

        padding: 5px;
        margin: 10px 0;
        border-radius: 2px;

        box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.205);
        transition: all .3s;

        > img {
            width: 120px;
            min-width: 115px;
            filter: brightness(.85);
            object-fit: cover;
            height: 75px;

            border: 2px solid rgb(136, 136, 136);
        }

        > h2 {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            padding: 0 0px 0 10px;
            text-transform: uppercase;
        }
    }

    &__item:hover {
        scale: (1.05);
        background-color: rgb(230, 230, 230);
        transition: all .3s;
    }
}
