@import "../../styles/variaveis";

@keyframes vibrate {
    0% {
      transform: translateX(-2px);
    }
    50% {
      transform: translateX(2px);
    }
    100% {
      transform: translateX(-2px);
    }
  }
  
  .div-btn {
      position: fixed;
      bottom: 5vh;
      right: 30px;
      z-index: 999;
  }
  
  .whats-btn {
      width: 55px;
      height: 55px;
      background: linear-gradient(to bottom, rgba(53, 219, 35, 1), rgba(39, 161, 26, 1));
      border: none;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.377);
  
      >  img {
          width: 37px;
      }
  
      &__notificacao {
          background-color: rgb(255, 58, 58);
          width: 18px;
          height: 18px;
          color: white;
          font-family: $fonteWhatsApp;
          font-size: 12px;
          font-weight: 200;
          border-radius: 100%;
          position: absolute;
          top: -3px;
          right: -3px;
          display: flex;
          justify-content: center;
          align-items: center;
      }
  }
  
  .div-btn.vibrating {
      animation: vibrate .2s infinite;
  }
  
