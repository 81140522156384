@import "../../styles/variaveis";

.aClinicaHome {
    background-color: rgb(250, 235, 215);
    width: 100%;

    display: flex;
    justify-content: space-around;
    padding: 4rem 5rem;

    &_div {
        display: flex;
        justify-content: flex-end;
    }

    &_conheca {
        width: 30%;
        min-width: 490px;
        > h1 {
            font-size: 48px;
        }

        > p {
            width: 85%;
            margin: 1rem 0 2rem 0;
        }
    }

    &_swiper {
        margin-right: 2%;
    }
}

.servicosHome {
    width: 100%;
    margin-top: 5vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    > h1 {
        font-size: 32px;
        font-weight: 400;
    }

    > h2 {
        font-size: 22px;
        font-weight: 300;
    }

    &__container {
        width: 100%;
        height: 600px;

        margin: 5vh 0 0 0;

        display: flex;

        .item {
            background-size: cover;
            width: 20%;
            height: 100%;

            .item_conteudo {
                width: 100%;
                height: 100%;

                background-color: transparent;
                transition: background-image 0.5s;

                padding: 0em 1em;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                position: relative;

                overflow: hidden;
                user-select: none;

                > h2 {
                    color: rgba(43, 43, 43, 0.877);

                    font-weight: 600;
                    font-size: 20px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    text-align: center;
                    /* text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.315); */

                    padding-bottom: 0.5rem;

                    z-index: 2;
                }

                > p {
                    min-height: 13%;
                    color: rgba(0, 0, 0, 0.842);

                    text-align: center;
                    font-weight: 500;
                    letter-spacing: 1px;
                    font-size: 14px;
                    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0);

                    z-index: 2;
                    margin-bottom: 10px;
                }
            }

            > .item_conteudo::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                background-image: linear-gradient(180deg, #faf3b92f 50%, #faf3b98e 80%, #ffce78fa 100%);
                transition: opacity 0.5s; /* Adicionando uma transição suave */
                z-index: 1;
            }

            > .item_conteudo:hover::before {
                opacity: 0;
            }

            > .item_conteudo:hover {
                background: linear-gradient(to top, rgba(66, 78, 145, 0.158) 5%, rgba(0, 0, 0, 0.219));
                cursor: pointer;

                > h2 {
                    color: white;
                    font-weight: 500;
                    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.911);
                }
                > p {
                    color: white;
                    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.582);
                }
            }

            /* #itemCoringa:hover {
                background: linear-gradient(to top, 
                    rgba(39, 70, 247, 0.281) 5%, 
                    rgba(0, 0, 0, 0.219));
            } */
        }

        > .item1 {
            background-image: url("../../assets/imagens/implantes.jpg");
        }

        > .item2 {
            background-image: url("../../assets/imagens/lentesDeContatoDental.jpg");
        }

        > .item3 {
            background-image: url("../../assets/imagens/cirurgia.jpg");
        }

        > .item4 {
            background-image: url("../../assets/imagens/ortodontia.jpg");
        }

        > .item5 {
            background-image: url("../../assets/imagens/botox.jpg");
        }
    }
}

.blogResumo {
    width: 100%;
    background-color: antiquewhite;

    display: flex;
    justify-content: center;

    padding: 5vh 0;

    &__container {
        display: flex;
        justify-content: center;

        &-info {
            padding-top: 4rem;
            width: 25%;

            > h1 {
                font-size: 40px;
            }

            > p {
                width: calc(100% - 50px);
            }
        }

        &-cards {
            max-width: 900px;
            display: flex;

            > a > .cardArtigo {
                margin: 0 10px;
            }
        }
    }
}

@media only screen and (max-width: 1700px) {
    .servicosHome {
        &__container {
            width: 100%;
            height: 500px;

            .item {
                .item_conteudo {
                    > h2 {
                        color: rgba(43, 43, 43, 0.877);

                        font-weight: 600;
                        font-size: 18px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        text-align: center;
                        /* text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.315); */

                        padding-bottom: 0.5rem;

                        z-index: 2;
                    }

                    > p {
                        min-height: 13%;
                        color: rgba(0, 0, 0, 0.842);

                        text-align: center;
                        font-weight: 500;
                        letter-spacing: 1px;
                        font-size: 13px;
                        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0);

                        z-index: 2;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1600px) {

    .aClinicaHome {
        padding: 3rem 1rem;
    
        &_conheca {
            width: 30%;
            min-width: 490px;
            > h1 {
                font-size: 40px;
            }
    
            > p {
                font-size: 15px;
                width: 85%;
                margin: 1rem 0 2rem 0;
            }
        }
    }

    .servicosHome {

        &__container {
            width: 100%;
            height: 450px;

            margin: 5vh 0 0 0;

            display: flex;

            .item {
                background-size: cover;
                width: 20%;
                height: 100%;

                .item_conteudo {
                    width: 100%;
                    height: 100%;

                    > h2 {
                        color: rgba(43, 43, 43, 0.877);

                        font-weight: 600;
                        font-size: 18px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        text-align: center;
                        /* text-shadow: 1px 1px 3px rgba(255, 255, 255, 0.315); */

                        padding-bottom: 0.5rem;

                        z-index: 2;
                    }

                    > p {
                        min-height: 13%;
                        color: rgba(0, 0, 0, 0.842);

                        text-align: center;
                        font-weight: 500;
                        letter-spacing: 1px;
                        font-size: 14px;
                        text-shadow: 1px 1px 1px rgba(255, 255, 255, 0);

                        z-index: 2;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1450px) {

    .aClinicaHome {
        padding: 3rem 1rem;
    
        &_conheca {
            width: 30%;
            min-width: 490px;
            > h1 {
                font-size: 40px;
            }
    
            > p {
                font-size: 15px;
                width: 85%;
                margin: 1rem 0 2rem 0;
            }
        }
    }

    .servicosHome {

        &__container {
            width: 100%;
            height: 400px;

            margin: 5vh 0 0 0;

            display: flex;

            .item {
                background-size: cover;
                width: 20%;
                height: 100%;

                .item_conteudo {
                    width: 100%;
                    height: 100%;

                    > h2 {
                        font-size: 16px;
                    }

                    > p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 1300px) {

    .aClinicaHome {
        padding: 3rem 1rem;
    
        &_conheca {
            width: 20%;
            min-width: 410px;
            > h1 {
                font-size: 36px;
            }
    
            > p {
                font-size: 14px;
                width: 85%;
                margin: 1rem 0 2rem 0;
            }
        }
    }

    .servicosHome {

        &__container {
            width: 100%;
            height: 400px;

            margin: 5vh 0 0 0;

            display: flex;

            .item {
                background-size: cover;
                width: 20%;
                height: 100%;

                .item_conteudo {
                    width: 100%;
                    height: 100%;

                    > h2 {
                        font-size: 16px;
                    }

                    > p {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    /* sadas */
}
