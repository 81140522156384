@import "../../styles/variaveis";

.redesSociais {    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__item {
        max-width: 350px;
        cursor: pointer;

        display: flex;
        align-items: center;

        > img {
            height: 27px;
            margin: 5px 10px;
        }

        span {
            font-weight: 300;
        }

        .maisGrosso {
            font-weight: 500;
        }
    }

    &__item:hover {
        color: $douradoEscuro;
    }

    &__item:nth-child(4):hover {
        text-decoration: none;
        cursor: auto;
    }

    &__item:nth-child(5) {
        align-items: start;
    }
}