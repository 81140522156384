@import '../../../styles/variaveis';

.mainArtigo {
    background-color: $branco;
    margin-top: 13vh;
}

.artigo {
    width: 1390px;
    margin: 5vh auto;

    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 20px;

    &__conteudo {
        width: 70%;
        > img {
            width: 100%;
            max-height: 500px;

            object-fit: cover;
            filter: brightness(85%);
        }

        > h1 {
            font-size: 32px;
            font-weight: 600;
            margin: .5em 0 .7em 0;
        }

        .markdown-container {
            > a {
                color: blue;
            }

            > h1 {
                font-size: 24px;
                font-weight: 700;
                margin-top: .5rem;
            }

            > h2 {
                font-size: 22px;
                margin-top: .5rem;
            }

            > h3 {
                font-size: 20px;
                font-weight: 600;
                margin-top: .5rem;
            }

            li {
                list-style: initial;
                margin-left: 3em;

                padding: .4em 0;
            }

            > p {
                font-size: 15px;
                font-weight: 400;
                padding: .6em 0;
            }

            /* strong {
                color: red;
            } */
        }
    }

    &__menuLateral {
        width: 370px;
        background-color: rgb(243, 243, 243);
        box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.274);

        padding: 1em;

        > h2 {
            font-size: 26px;
            font-weight: 600;
        }
    }
}

@media screen and (max-width: 1380px) {
    .artigo {
        width: 100%;
        padding: 0 25px;

        &__conteudo {
            > img {
                width: 100%;
                max-height: 400px;
            }
        }
    }    
}

@media screen and (max-width: 1080px) {
    .artigo {
        width: 100%;
        display: flex;
        flex-direction: column;

        &__conteudo {
            width: 100%;
            > img {
                width: 100%;
                max-height: 400px;
            }
        }
    }    
}

@media screen and (max-width: 768px) {
    .artigo {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 12px;
        margin: 0;

        &__conteudo {
            width: 100%;
            > img {
                width: 100%;
                max-height: 400px;
            }
        }
    }    
}