@import "../../styles/variaveis";

.fotos {
    width: 100%;
    max-width: 1300px; /* Ajuste conforme necessário */
    margin: 0 auto;

    margin-bottom: 5vh;
  
  .grid-container {
    margin: 0 10px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Colunas responsivas */
    grid-gap: 10px;
  }
  
  .foto-item {
    width: 100%;
    padding-bottom: 100%; /* Mantém a proporção da imagem */
    position: relative;

  }
  
  .foto-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Para preencher completamente o espaço */
    
    position: absolute;

  }
}
  