@import "../../styles/variaveis";

.logo {
    height: 8vh;
    display: flex;
    align-items: center;


    &__imgRecortado img {
        width: auto;
        height: 6vh;
        margin-right: 0px;
        min-height: 42px;
    }

    &__img img {
        width: 50px;
        margin-right: 10px;
    }

    &__home h2 {
        font-size: 28px;
        font-weight: 200;
    }

    &__Branco h2 {
        font-size: 28px;
        font-weight: 200;
        color: white;
    }

    &__name h2 {
        font-size: 28px;
        font-weight: 200;
    }

    &__aMao {
        font-family: $fonteHandWhite;
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 1px;
    }
}

@media only screen and (max-height: 650px) {
    
    .logo {    
        &__imgRecortado img {
            width: auto;
            height: 7vh;
            margin-right: 0px;
            min-height: 42px;
        }
    
        &__img img {
            width: 50px;
            margin-right: 10px;
        }
    
        &__home h2 {
            font-size: 24px;
            font-weight: 300;
        }
    
        &__Branco h2 {
            font-size: 24px;
            font-weight: 300;
        }
    
        &__name h2 {
            font-size: 24px;
            font-weight: 300;
        }
    }
}


@media only screen and (max-width: 1150px) {
    .logo {    
        &__imgRecortado img {
            width: auto;
            height: 6vh;
            margin-right: 0px;
            min-height: 42px;
        }
    
        &__img img {
            width: 50px;
            margin-right: 10px;
        }
    
        &__home h2 {
            min-width: 200px;
            font-size: 20px;
            font-weight: 300;
        }
    
        &__Branco h2 {
            min-width: 200px;
            font-size: 20px;
            font-weight: 300;
        }
    
        &__name h2 {
            min-width: 200px;
            font-size: 22px;
            font-weight: 300;
        }
    }
}