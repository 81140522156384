@import "../../styles/variaveis";

.faixaLocation {
    background: rgb(46, 46, 46);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 110px;

    &__span {
        font-size: 16px;
        font-weight: 300;
    }

    > h1 {
        font-size: 36px;
        font-weight: 500;
    }
}